import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './dcodeMarkerStamp2.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
import {useTranslation} from "react-i18next";
import WideSection from "../../shared/WideSection/WideSection";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_dcodeMarkerStamp"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const DcodeMarkerStamp2 = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const content =
        <>
            {renderImageByFileName(imagesFluid, 'DMS_1.png', 'DMS 1')}
            <div className={css.text}>A set of Dcode markers.</div>
            {renderImageByFileName(imagesFluid, 'DMS_2.png', 'DMS 2')}
            <div className={css.image}>
                {renderImageByFileName(imagesFluid, 'DMS_Matrix.png', 'DMS Matrix')}
            </div>
        </>

    return (
        <WideSection
            title={''}
            bigContent={<div className={css.bigContent}>
                {content}
            </div>}
            smallContent={<div className={css.smallContent}>
                {content}
            </div>}
            blackBackground={true}
        />
    );
};

export default DcodeMarkerStamp2;
