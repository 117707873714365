import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './dcodeMarkerStamp.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import MainButton, {mainButtonArrow} from "../../shared/MainButton/MainButton";
import PdfWhiteIco from "../../../images/icons/document_pdf_white.svg";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_dcodeMarkerStamp"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 480, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const DcodeMarkerStamp = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'DMS';

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'55%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'DMS_hero.png', 'DMS')}
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'45%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    The Dcode Marker Stamp is a manual tool for marking a Dcode on the timber log face.
                    <br/><br/>
                    The use of the EDG is required to generate the variable Dcode.
                    <br/><br/>
                    By using the individual marks, the alphanumeric Dcode signs are being indented one by one. The frame
                    is positioning the area of the Dcode indention.
                    <br/><br/>
                    A simple and easy process allows for the adaptation of the level of required hammering motion to the
                    species' hardness.
                </Text>
                <br/>
            </SectionTextWrapper>
        </div>
    )
};

export default DcodeMarkerStamp;
