import React from "react";
import css from './sectionButtons.module.scss';

const SectionButtons = props => {
    return (
        <div className={css.wrapper}>
            {props.children}
        </div>
    )
}

export default SectionButtons;