import React from 'react';
import css from './wideSection.module.scss';
import Text from "../../shared/Text/Text";
import SectionButtons from "../../shared/Section/SectionButtons";

const WideSection = props => {
    const {
        title = '',
        bigContent = null,
        smallContent = null,
        text = '',
        buttons = null,
        blackBackground = false,
    } = props;

    return (
        <div className={css.wrapper}>
            {blackBackground && <div className={css.wrapperBg}/>}
            <div className={css.content}>
                <div className={css.title}>{title}</div>
                <div className={css.bigContentWrapper}>{bigContent}</div>
                <div className={css.smallContentWrapper}>{smallContent}</div>
                {text &&
                    <div className={css.text}>
                        <Text>{text}</Text>
                    </div>
                }
                {buttons &&
                    <div className={css.button}>
                        <SectionButtons>
                            {buttons}
                        </SectionButtons>
                    </div>
                }
            </div>
        </div>
    );
};

export default WideSection;
